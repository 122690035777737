import logo from './svd watermerk wit.png';
import './App.css';
import Navbar from './components/Navbar.js'
import MasonryImageList from './components/masonry';
import { BrowserRouter as Router, Routes, Route }
    from 'react-router-dom';
    import Home from './pages';
    import About from './pages/about';

function App(props) {

  // function mapFiles(context) {
  //   const keys = context.keys();
  //   const values = keys.map(context);
  //   return keys.reduce((accumulator, key, index) => ({
  //     ...accumulator,
  //     [key]: values[index],
  //   }), {});
  // }
  
  // const images = require.context('/Photos', true, IMAGES_REGEX, 'eager');

  return (
    <div className="App">
        
        <img src={logo} className="App-logo" alt="logo" />
       
        <div>
          <Router>
            <Navbar />
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/about' element={<About />} />
                <Route path='/gallery' element={<MasonryImageList />} />
            </Routes>
        </Router>
        </div>
      
    </div>
  );

}

export default App;

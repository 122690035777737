import React from "react";
import './style.css'
import Box from '@mui/material/Box';
const About = () => {
	return (
		<div>
			<Box  style={{width: '80%', marginLeft: '10%', textAlign: 'start'}}>
			<p style={{fontSize: '20px'}}>
				Hi, thanks for visiting!<br></br>
				<br></br>
				The first time I got my hands on a proper camera back in 2009 it felt like it was long overdue.<br></br>
				Ever since I've collected a lot of awesome memories and perspectives I'd like to share with you here.<br></br>
				<br></br>
				As a photographer and visual artist I have an interest in many different subjects, ranging from the tiniest critters, grand landscapes or extravagant parties to creative long or multiple exposures.<br></br>
				I especially like to play with reflections, time variations and movement, trying to create sights unavailable to our own eyes. <br></br>
				<br></br>
				All pictures are available in print or for other uses, feel free to <a href="mailto:sjoerd@s-v-d.com">contact me</a> about the possibilities!<br></br>
				<br></br>
				Peace & love<br></br>
				<br></br>
				Sjoerd<br></br>

			</p></Box>
		</div>
	);
};

export default About;
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { useEffect, useState } from "react";
import Modal from 'react-modal';
import './lightboks.css'
import { AiFillRightCircle, AiFillLeftCircle, AiFillCloseCircle } from "react-icons/ai";


export default function MasonryImageList() {

    const [items, setItems] = useState([]);
    const [index, setIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false)
   
      // images.keys().forEach(key => console.log(key));

    const images = require.context('../../public/Photos', false, /^.*$/);

  useEffect(()=>{
    let imagePaths = images.keys().map(key=> process.env.PUBLIC_URL + '/Photos/'+ key.slice(2));
		setItems(imagePaths); 
	}, [images])


  const customStyles = {
    
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'black',
      
    },
  };
  

  function handleClick(ix){
    setIndex(ix);
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function handleNext(){
    (index+1>items.length-1)? setIndex(0):setIndex(index+1);
  }

  function handlePrev(){
    (index-1<0)? setIndex(items.length-1):setIndex(index-1);
  }

  return (
    <div>
    <Box id="scrolableDiv" sx={{ width: '80%', height: 'auto', overflowY: 'visible', marginLeft: '10%'}}>
      <ImageList variant="masonry" cols={3} gap={6}>

        
        {items.map((item,i) => (
          <ImageListItem key={i} index={i} onClick={()=>handleClick(i)}>
            <img
              index={i}
              src={`${item}?w=248&fit=crop&auto=format`}
              // srcSet={{slides}
              alt='oi'
              style={{ minHeight: 10 }} 
             
           />
          </ImageListItem>
          
        ))}
        </ImageList>
    </Box>
    <Modal 
          
           isOpen={isOpen}
           onRequestClose={closeModal}
           style={customStyles}
     >
            <img
              src={items[index]}
              alt='oi'
              style={{ minHeight: 10,   objectFit: 'contain',
                maxWidth: '100%',
                maxHeight: '100%',
                textAlign: 'center',
                width: 'auto',
                height: 'auto'}} 
            />
          <AiFillLeftCircle className='ril__navButtons ril__navButtonPrev' onClick={handlePrev}></AiFillLeftCircle>
          <AiFillRightCircle className='ril__navButtons ril__navButtonNext' onClick={handleNext}></AiFillRightCircle>
          <AiFillCloseCircle className='ril__closeButton' onClick={closeModal}></AiFillCloseCircle>
      </Modal>
  </div>
  );
}

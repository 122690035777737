import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";

export const Nav = styled.nav`

height: 5%;
display: inline-block;
`;

export const NavLink = styled(Link)`
color: grey;
align-items: center;
text-decoration: none;
padding: 0 1rem;
height: 100%;
cursor: pointer;
&.active {
	color: white;
}
`;



export const NavMenu = styled.div`
text-align: center;
display: inline-block;
align-items: center;
/* Second Nav */
/* margin-right: 24px; */
/* Third Nav */
/* width: 100vw;
white-space: nowrap; */
`;

import React from 'react';
import image from '../images/_MG_4825.JPG'
import './style.css'

const Home = () => {
	return (
		<div>
		<img src={image} className='image' alt="mainimage" />
		</div>
	);
};

export default Home;
